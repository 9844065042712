<template>
  <div class="userList">
    <h1>后台用户管理</h1>
    <el-form :inline="true">
      <el-form-item label="电话">
        <el-input style="width: 120px;" placeholder="请输入完整电话" v-model="searchParam.phone" clearable />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="add()">新增</el-button>
          <el-button type="warning" :disabled="show.selected.length != 1" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="show.selected.length != 1 || !show.selected[0].hasUsing" @click="setUsing(false)">禁用</el-button>
          <el-button type="success" :disabled="show.selected.length != 1 || show.selected[0].hasUsing" @click="setUsing(true)">启用</el-button>
          <el-button type="info" :disabled="show.selected.length != 1" @click="data.resetPasswordValue = ''; show.resetPasswordShow = true;">重置密码</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.userList" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="70">
        <template #default="scope">
          <el-tag v-if="scope.row.admin">超管</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="账号" width="200" />
      <el-table-column prop="nick" label="姓名、昵称" width="200" show-overflow-tooltip>
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">{{ scope.row.nick }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话" width="140" />
      <el-table-column prop="admin" label="类型" width="100">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.createCityOfficeName">创城办</el-tag>
          <el-tag type="danger" v-if="scope.row.responsibleUnitName">责任单位</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="admin" label="组织" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.createCityOfficeName">{{ scope.row.createCityOfficeName }}</el-text>
          <el-text v-if="scope.row.responsibleUnitName">{{ scope.row.responsibleUnitName }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="hasUsing" label="状态" width="100">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.hasUsing">启用</el-tag>
          <el-tag type="danger" v-if="!scope.row.hasUsing">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" label="角色" show-overflow-tooltip />
      <el-table-column prop="createDatetime" label="创建时间" width="180" />
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.userTotal" @change="commitFindList()" />

    <el-dialog v-model="show.resetPasswordShow" title="重置密码：" width="550" :close-on-click-modal="false" :close-on-press-escape="false">
      <p>
        <el-text>请输入新密码：</el-text>
      </p>
      <p>
        <el-input v-model="data.resetPasswordValue" placeholder="10~16位字符" />
      </p>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="resetPassword()">确认重置密码</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  userTotal: 0,
  userList: [],
  menuList: [],
  resetPasswordValue: '',
})

const show = reactive({
  resetPasswordShow: false,
  selected: [],
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.phone = ''
  searchParam.using = null
  searchParam.roleId = null
}
resetSearchParam()

const commitFindList = () => {
  api.get('/backend/user/getPage', { params: searchParam }).then(res => {
    data.userList = res.list
    data.userTotal = res.total
  })
}
commitFindList()

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'userDetail', null)
  router.push('/userDetail')
}

const update = (row) => {
  const user = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'userDetail', user)
  router.push('/userDetail')
}

const setUsing = (hasUsing) => {
  const user = tabRef.value.getSelectionRows()[0]
  const messageName = hasUsing ? '启用' : '禁用'
  ElMessageBox.confirm('', {
    title: '确认' + messageName + '：',
    message: '将要' + messageName + ' ' + user.nick + ' 。',
    autofocus: false,
    confirmButtonText: messageName,
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/user/setUsingById', { id: user.id, using: hasUsing }).then(() => {
      ElMessage.success(messageName + '成功')
      commitFindList()
    })
  })
}

const resetPassword = () => {
  const user = tabRef.value.getSelectionRows()[0]
  if (data.resetPasswordValue.length < 10 || data.resetPasswordValue.length > 16) {
    ElMessage.error('密码长度为10-16位')
    return
  }
  api.post('/backend/user/resetPasswordById', { id: user.id, password: data.resetPasswordValue }).then(res => {
    ElMessage.success('重置密码成功')
    show.resetPasswordShow = false
  })
}

</script>

<style lang="less"></style>